.home {
 &--category {
  height: 100%;

  .link {
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    padding: 50px 25px 25px;
    height: 320px;
    transition: all .1s ease-in-out;

    .title-2 {
      position: absolute;
      top: 30px;
      left: 45px;
      transition: all .2s ease-in-out;
    }

    @include media-breakpoint-down(xxl) {
      height: 280px;
    }

    @include media-breakpoint-down(xl) {
      height: 230px;
    }

    @include media-breakpoint-down(xl) {
      height: 260px;
    }

    @include media-breakpoint-down(lg) {
      height: 230px;
    }

    @include media-breakpoint-down(md) {
      height: 210px;
    }

    &.background-yellow {
      color: $black;
    }

    &:hover {
      transform: scale(.93);
      box-shadow: 5px 0 20px rgba(0,0,0, .3);
      .title-2 {
        left: 60px;
      }
    }

    .sprite {
      display: block;
    }
  }
  @include media-breakpoint-down(sm) {
   .sprite {
     transform: scale(.9);
   }
  }
 }
}

.calendar-sidebar > .month-list > .calendar-months > li {

  font-size: 19px;
}

.calendar-wrapper {
  display: inline-block;
  position: relative;
  border-radius: 5px;
  width: 350px;
  overflow: hidden;
}

#calendar-button-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 5px;
  background-color: map.get($colors, 'green-dark-alt') !important;
  color: $white;

  > * {
    font-size: 30px;
  }
  > *:first-child {
    display: block;
  }
  > *:last-child {
    display: none;
  }
  &.open {
    color: $white;
    > *:first-child {
      display: none;
    }
    > *:last-child {
      display: block;
    }
  }
}

.evo-calendar {
  box-shadow: none !important;

  &.sidebar-hide {
    .calendar-sidebar {
      display: none;
    }
  }

  .calendar-sidebar {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    transform: none !important;
    width: 100%;
    background-color: map.get($colors, 'green-dark-alt') !important;

    .month-list {
      text-align: center;
      background-color:transparent !important;
      .calendar-months > li.active-month {
        background-color: saturate(map.get($colors, 'green-dark-alt'), 30%);
      }
      .calendar-months > li:hover {
        background-color: desaturate(map.get($colors, 'green-dark-alt'), 10%);
      }
    }
    .calendar-year  {
      padding: 10px 20px;
      box-shadow: none !important;
    }

    .calendar-year > p {
      font-size: 24px;
    }
    .calendar-year > button.icon-button {
      transform: scale(.7) translateY(2px);
    }
  }

  th[colspan="7"] {
    font-size: 20px;
    color: $black;
    padding-top: 1px;
    &:after {
      display: none;
    }
  }

  .event-indicator {
    width: 100%;
    left: 0;
    line-height: 0;
    text-align: center;
    transform: none;
    top: 24px !important;
  }

  .event-indicator > .type-bullet{
    display: inline-block;
    float: none;
    padding: 1px;
  }

  .event-indicator > .type-bullet > div {
    width: 8px;
    height: 8px;
    &.type-cat-jaden-lesperances {
      background-color: map.get($colors, 'green-light');
    }
    &.type-cat-recyclbois {
      background-color: map.get($colors, 'orange');
    }
    &.type-cat-boost-insertion {
      background-color: map.get($colors, 'green-dark');
    }
    &.type-cat-propulse {
      background-color: map.get($colors, 'red');
    }
    &.type-cat-delanmen {
      background-color: map.get($colors, 'blue');
    }
    &.type-cat-impactjeunes {
      background-color: map.get($colors, 'yellow');
    }
  }

  .event-container > .event-info {
    text-align: left;
  }

  .event-container > .event-icon > div {
    &.event-bullet-cat-jaden-lesperances {
      background-color: map.get($colors, 'green-light');
    }
    &.event-bullet-cat-recyclbois {
      background-color: map.get($colors, 'orange');
    }
    &.event-bullet-cat-boost-insertion {
      background-color: map.get($colors, 'green-dark');
    }
    &.event-bullet-cat-propulse {
      background-color: map.get($colors, 'red');
    }
    &.event-bullet-cat-delanmen {
      background-color: map.get($colors, 'blue');
    }
    &.event-bullet-cat-impactjeunes {
      background-color: map.get($colors, 'yellow');
    }

  }

  .calendar-day {
    position: relative !important;
    padding: 0 !important;

    .day {
      height: 40px !important;
      padding: 0 !important;
      width: 40px !important;
      border: 0 !important;

      &:hover {
        background: none !important;
        border: none !important;
      }
    }


    .day.calendar-active {
      border: 0 !important;
    }
  }
  .calendar-inner {
    background-color: map.get($colors, 'grey-light-alt');
    padding: 10px;
    transition: none !important;

    .calendar-table {
      font-size: 16px;
    }

    .calendar-header-day {
      padding: 10px 0 10px;
    }
  }

  #eventListToggler {
    display: block !important;
    background-color: map.get($colors, 'green-dark-alt') !important;
    box-shadow: none !important ;
    padding-top: 4px !important;
    transform: none !important;
    right: 0 !important;
    width: 40px !important;
    height: 40px !important;
    top: 0 !important;

    .icon-button {
      &:after {
        content: "✕";
        color: $white;
        font-size: 20px;
      }

      .chevron-arrow-right {
        display: none;
      }
    }
  }

  .calendar-inner {
    max-width: 100% !important;
    width: 100% !important;
  }

  .calendar-events {
    padding: 10px 50px 0;
    color: $black;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    display: block;
    background-color: map.get($colors, 'grey-light-alt');
  }

  .event-container {
    margin-bottom: 0;
    &:before {
      background-color: map.get($colors, 'grey');
    }
    &:hover {
      box-shadow: none !important;
      background-color: transparent !important;
    }
  }

  .event-container > .event-info {
    padding-top: 10px;
  }


  .event-container > .event-info > .event-desc{
    margin-top: 0 !important;
    a {
      text-decoration: underline;
      display: block;
      margin-top: 5px;
    }
  }

  .calendar-events > .event-header > p {
    font-size: 20px;
    text-align: left;
    font-weight: normal;
    color: $black;
    padding-left: 18px;
}
.event-container > .event-info > p {
  color: $black;
  font-weight: normal;
  &.event-title {
    font-size: 17px;
    font-weight: normal;
  }

}

  &.event-hide {
    .calendar-events {
      display: none !important;
    }
    #eventListToggler {
      display: none !important;
    }

  }
}


@media screen and (max-width: 425px) {
  #sidebarToggler {
    display: none !important;
  }
  .calendar-sidebar {
      width: 100%;
  }
  .sidebar-hide .calendar-sidebar {
      height: 43px;
  }
  .sidebar-hide .calendar-sidebar {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
      -webkit-box-shadow: none;
      box-shadow: none;
  }
  .calendar-sidebar > .calendar-year {
      position: relative;
      padding: 10px 20px;
      text-align: center;
      background-color: none;
              box-shadow: 0;
              background-color: transparent !important;
  }
  .calendar-sidebar > .calendar-year > button.icon-button {
      width: 14px;
      height: 14px;
  }
  .calendar-sidebar > .calendar-year > button.icon-button > span {
      border-right-width: 3px;
      border-bottom-width: 3px;
  }
  .calendar-sidebar > .calendar-year > p {
      font-size: 18px;
      margin: 0 10px;
  }
  .calendar-sidebar > .month-list {
      position: relative;
      width: 100%;
      height: calc(100% - 43px);
      overflow-y: auto;
      background-color: none;
      -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
              transform: translateY(0);
      z-index: -1;
  }
  .sidebar-hide .calendar-sidebar > .month-list {
      -webkit-transform: translateY(-100%);
          -ms-transform: translateY(-100%);
              transform: translateY(-100%);
  }
  .calendar-sidebar > .month-list > .calendar-months {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      list-style-type: none;
      margin: 0;
      padding: 0;
      padding: 10px;
  }
  .calendar-sidebar > .month-list > .calendar-months::after {
      content: "";
      clear: both;
      display: table;
  }
  .calendar-sidebar > .month-list > .calendar-months > li {
      padding: 10px 20px;
      font-size: 20px;
      /* width: 50%;
      float: left; */
  }
  .calendar-sidebar > span#sidebarToggler {
      -webkit-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
              transform: translate(0, 0);
      top: 0;
      bottom: unset;
      -webkit-box-shadow: none;
      box-shadow: none;
  }

  th[colspan="7"]::after {
      bottom: 0;
  }

  .calendar-inner {
      margin-left: 0;
      padding: 53px 0 40px 0;
      float: unset;
  }
  .calendar-inner::after {
      content: none;
      opacity: 0;
  }
  .sidebar-hide .calendar-inner,
  .event-hide .calendar-inner,
  .calendar-inner {
      max-width: 100%;
  }
  .calendar-sidebar > span#sidebarToggler,
  #eventListToggler {
      width: 40px;
      height: 40px;
  }
  button.icon-button > span.chevron-arrow-right {
      border-right-width: 4px;
      border-bottom-width: 4px;
      width: 18px;
      height: 18px;
      -webkit-transform: translateX(-3px) rotate(-45deg);
          -ms-transform: translateX(-3px) rotate(-45deg);
              transform: translateX(-3px) rotate(-45deg);
  }

  button.icon-button > span.bars,
  button.icon-button > span.bars::before,
  button.icon-button > span.bars::after {
      height: 4px;
  }
  button.icon-button > span.bars::before {
      top: -8px;
  }
  button.icon-button > span.bars::after {
      bottom: -8px;
  }
  tr.calendar-header .calendar-header-day {
      padding: 0;
  }
  tr.calendar-body .calendar-day {
      padding: 8px 0;
  }
  tr.calendar-body .calendar-day .day {
      padding: 10px;
      width: 40px;
      height: 40px;
      font-size: 16px;
  }
  .event-indicator {
      -webkit-transform: none !important;
          -ms-transform: none !important;
              transform: none !important;
  }
  .event-indicator > .type-bullet {
      padding: 1px;
  }
  .event-indicator > .type-bullet > div {
      width: 6px;
      height: 6px;
  }
  .event-indicator {
    -webkit-transform: none !important;
    -ms-transform: none !important;
        transform: none !important;
}
  tr.calendar-body .calendar-day .day.calendar-today .event-indicator,
  tr.calendar-body .calendar-day .day.calendar-active .event-indicator {
      -webkit-transform: translate(-50%, 3px);
          -ms-transform: translate(-50%, 3px);
              transform: translate(-50%, 3px);
  }
  .calendar-events {
      position: relative;
      padding: 20px 15px;
      width: 100%;
      height: 185px;
      -webkit-box-shadow: 0 5px 18px -3px rgba(0, 0, 0, 0.15);
      box-shadow: 0 5px 18px -3px rgba(0, 0, 0, 0.15);
      overflow-y: auto;
      z-index: 0;
  }
  .event-hide .calendar-events {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);
      padding: 0 15px;
      height: 0;
  }
  .calendar-events > .event-header > p {
      font-size: 20px;
  }
  .evo-calendar .calendar-sidebar {
    overflow-y: hidden !important;
  }
  .event-list > .event-empty {
      padding: 10px;
  }
  .event-container::before {
      /* height: calc(100% - 12.5px); */
      transform: translate(21.5px, 25px);
  }
  .event-container:last-child.event-container::before {
      height: 22.5px;
      transform: translate(21.5px, 0);
  }
  .event-container > .event-icon {
      width: 45px;
      height: 45px;
  }
  .event-container > .event-icon::before {
      left: 21px;
  }
  .event-container:last-child > .event-icon::before {
      height: 50%;
  }
  .event-container > .event-info {
      width: calc(100% - 45px);
  }
  .event-hide #eventListToggler,
  #eventListToggler {
      top: calc(100% - 185px);
      right: 0;
      -webkit-transform: translate(0, -100%);
      -ms-transform: translate(0, -100%);
      transform: translate(0, -100%);
  }
  .event-hide #eventListToggler {
      top: 100%;
  }
  #eventListToggler button.icon-button > span.chevron-arrow-right {
      position: relative;
      display: inline-block;
      -webkit-transform: translate(0, -3px) rotate(45deg);
          -ms-transform: translate(0, -3px) rotate(45deg);
              transform: translate(0, -3px) rotate(45deg);
  }
}
