@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/MaterialIcons-Regular.woff') format('woff');
}

@font-face {
  font-family: 'roboto_condensed_italic';
  src: url('fonts/RobotoCondensed-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensed_regular';
  src: url('fonts/RobotoCondensed-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensed_bold';
  src: url('fonts/RobotoCondensed-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensed_bold_italic';
  src: url('fonts/RobotoCondensed-BoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$font-regular: 'roboto_condensed_regular', sans-serif;
$font-bold: 'roboto_condensed_bold', sans-serif;

$fonts: (
  (title-1, 24, $font-bold, normal, 1.2, title),
  (title-2, 22, $font-bold, normal, 1.2, title),
  (title-3, 20, $font-bold, normal, 1.2, title),
  (title-4, 18, $font-bold, normal, 1.2, title),
  (text-1, 18, $font-bold, normal, 1.2, text),
  (text-2, 18, $font-regular, normal, 1.2, text),
  (text-3, 17, $font-bold, normal, 1.2, text),
);

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; 
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
 }


html {
  color: $black;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-regular !important;
}

@each $label,
$font-size,
$font-weight,
$font-style,
$line-height,
$type in $fonts {
  .#{$label} {
    line-height: $line-height;
    font-style: $font-style;
    font-family: $font-weight;
    @include font-size($font-size);

    @if $type ==title {
      @include media-breakpoint-down(md) {
        @include font-size(calc(math.div($font-size, 1.1)));
      }
    }

    @include media-breakpoint-down(sm) {
      @if type == text and size > 15 {
        @include font-size(calc(math.div($font-size,1.2)));
      } 
    }
  }
}