$buttons: (
  (button-green-light,
  inline-block,
  18,
  $font-regular,
  uppercase,
  1.2,
  14px 28px,
  center,
  50px,
  $white,
  map.get($colors, 'green-light'),
  2px solid map.get($colors, 'green-light'),
  map.get($colors, 'green-light'),
  $white, 
  2px solid map.get($colors, 'green-light')),
  (button-orange,
  inline-block,
  18,
  $font-regular,
  uppercase,
  1.2,
  14px 28px,
  center,
  50px,
  $white,
  map.get($colors, 'orange'),
  2px solid map.get($colors, 'orange'),
  map.get($colors, 'orange'),
  $white, 
  2px solid map.get($colors, 'orange')),
  (button-orange,
  inline-block,
  18,
  $font-regular,
  uppercase,
  1.2,
  14px 28px,
  center,
  50px,
  $white,
  map.get($colors, 'orange'),
  2px solid map.get($colors, 'orange'),
  map.get($colors, 'orange'),
  $white, 
  2px solid map.get($colors, 'orange')),
  (button-green-dark,
  inline-block,
  18,
  $font-regular,
  uppercase,
  1.2,
  14px 28px,
  center,
  50px,
  $white,
  map.get($colors, 'green-dark'),
  2px solid map.get($colors, 'green-dark'),
  map.get($colors, 'green-dark'),
  $white, 
  2px solid map.get($colors, 'green-dark')),
  (button-green-dark-alt,
  inline-block,
  18,
  $font-regular,
  uppercase,
  1.2,
  14px 28px,
  center,
  50px,
  $white,
  map.get($colors, 'green-dark-alt'),
  2px solid map.get($colors, 'green-dark-alt'),
  map.get($colors, 'green-dark-alt'),
  $white, 
  2px solid map.get($colors, 'green-dark-alt')),
  (button-red,
  inline-block,
  18,
  $font-regular,
  uppercase,
  1.2,
  14px 28px,
  center,
  50px,
  $white,
  map.get($colors, 'red'),
  2px solid map.get($colors, 'red'),
  map.get($colors, 'red'),
  $white, 
  2px solid map.get($colors, 'red')),
  (button-blue,
  inline-block,
  18,
  $font-regular,
  uppercase,
  1.2,
  14px 28px,
  center,
  50px,
  $white,
  map.get($colors, 'blue'),
  2px solid map.get($colors, 'blue'),
  map.get($colors, 'blue'),
  $white, 
  2px solid map.get($colors, 'blue')),
  (button-yellow,
  inline-block,
  18,
  $font-regular,
  uppercase,
  1.2,
  14px 28px,
  center,
  50px,
  $black,
  map.get($colors, 'yellow'),
  2px solid map.get($colors, 'yellow'),
  map.get($colors, 'yellow'),
  $white, 
  2px solid map.get($colors, 'yellow')),
);

@each $label,
  $display,
  $font-size,
  $font-family,
  $text-transform,
  $line-height,
  $padding,
  $text-align,
  $border-radius,
  $color,
  $background-color,
  $border,
  $hover-color,
  $hover-background,
  $hover-border in $buttons {
  .#{$label} {
    background-color: $background-color;
    border: $border;
    border-radius: $border-radius;
    color: $color;
    display: $display;
    font-family: $font-family;
    line-height: $line-height;
    padding: $padding;
    text-align: $text-align;
    text-transform: $text-transform;
    transition: all .2s ease-in-out;
    @include font-size($font-size);

    @include media-breakpoint-down(sm) {
      &.w-sm-full {
        display: block !important;
        width: 100% !important;        
      }
    }

    > * {
      display: inline-block;
      vertical-align: middle;
      &:last-child {
        margin-left: 5px;
      }
    }


    &:hover,
    &:focus {
      background-color: $hover-background;
      color: $hover-color;
      border: $hover-border;
    }
  }
}

.button {
  &--mobile {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    height: 20px;
    position: relative;
    transform: rotate(0deg);
    width: 30px;
    z-index: 2;
    @include transition(all, 0.2s, ease-in-out);
  
    span {
      background: map.get($colors, 'green-dark-alt');
      display: block;
      height: 3px;
      left: 0;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      width: 100%;
      @include transition(all, 0.2s, ease-in-out);
    }
  
    span:nth-child(1) {
      top: 0;
    }
  
    span:nth-child(2),
    span:nth-child(3) {
      top: 9px;
    }
  
    span:nth-child(4) {
      top: 18px;
    }  
  }

  &--scroll-top {
    bottom: 30px;
    position: fixed;
    right: 30px;
    z-index: 99;
  
    @include media-breakpoint-down(sm) {
      bottom: 15px;
      right: 15px;
    }
    
    &:hover,
    &:focus {}  
  }
}

.menu-open {
  .button--mobile {
    span:nth-child(1) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  
    span:nth-child(2) {
      transform: rotate(45deg);
    }
  
    span:nth-child(3) {
      transform: rotate(-45deg);
    }
  
    span:nth-child(4) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  }
}