.item-contact {
  .title-contact {
    position: relative;
    padding-left: 25px;
    font-family: $font-bold;
    text-transform: uppercase;
    color: map.get($colors, 'green-dark-alt');
    @include font-size(16);
  }

  .title-contact .material-icons {
    position: absolute;
    top: 1px;
    left: 0;
    @include font-size(18);
  }

  .list {
    margin-top: 8px;
    padding-left: 25px;
  }

  .list li {
    display: block;
    position: relative;
    padding-left: 11px;
    line-height: 1.2em;
    word-break: break-word;
    @include font-size(15);
  }

  .list li:not(:last-child) {
    margin-bottom: 7px;
  }

  .list li:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    width: 4px;
    height: 1px;
    background-color: map.get($colors, 'green-dark-alt');
  }

  @include media-breakpoint-down(sm) {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }
}