body {
	@include media-breakpoint-up(lg) {
		&.header-small {
			padding-top: 162px;
			// @include media-breakpoint-down(xl) {
			// 	padding-top: 150px;
			// }
			#header {
				.navigation-sub {
					top: 163px;
				}
				@include media-breakpoint-down(xl) {
				.navigation-sub {
					top: 153px;
				}
			 }
				position: fixed;
				top: -80px;
				left: 0;
				z-index: 101;
				.logo {
					display: block;
					transform: scale(.55) translate(-120px, 50px);
				}
			}
		}
	}
}

$categories : (
	(category-jaden-lesperances, category-icon-1, map.get($colors, 'green-light')),
	(category-recyclbois, category-icon-2, map.get($colors, 'orange')),
	(category-boost-insertion, category-icon-3, map.get($colors, 'green-dark')),
	(category-propulse, category-icon-4, map.get($colors, 'red')),
	(category-delanmen, category-icon-1, map.get($colors, 'blue')),
	(category-impactjeunes, category-icon-1, map.get($colors, 'yellow')),
);

#header {
	//background-color: map.get($colors, 'black');
	background-color: $white;
	margin-bottom: 15px;
	position: relative;
	padding: 0;
	width: 100%;
	z-index: 10;
	@include transition(all, 0.2s, ease-in-out);

	@include media-breakpoint-down(md) {
		.logo {
			width: 275px;
			display: block;
		}
	}
	@include media-breakpoint-down(sm) {
		.logo {
			width: 200px;
		}
	}


}

#button--mobile {
	position: absolute;
	top: 70px;
	right: 0;
	
	@include media-breakpoint-down(md) {
		right: 15px;
	}
	@include media-breakpoint-down(sm) {
		top: 50px;
	}	

}

#navigation-main {
	display: flex;

	.item {
		flex-grow: 1;

		@include media-breakpoint-up(lg) {
			&:last-child {
				.link {
					padding-right: 0;
				}
			}
		}

	}

	.link {
		color: map.get($colors, 'black');
		display: block;
		padding: 15px;
		text-align: right;
		position: relative;
		@include font-size(16);

		@include media-breakpoint-down(lg) {
			text-align: center;
			padding-top: 10px;
		}
		
		&:focus,
		&:hover {
			color: map.get($colors, 'green-dark-alt');
		}

		&.active {
			color: map.get($colors, 'green-dark-alt');
			text-decoration: underline;
		}

		@include media-breakpoint-up(lg) {
			padding: 0px;
		}
	}

	.navigation-sub-list {
		color: map.get($colors, 'white');
		padding: 30px;

		@include media-breakpoint-down(xxl) {
			padding: 20px;
		}

		.title-4 {
			border-bottom: 2px solid map.get($colors, 'white');
			color:  map.get($colors, 'white') !important;
		}

		&.background-yellow {
			color: map.get($colors, 'black');
			
			.link-sub {
				color: map.get($colors, 'black');
			}
			.title-4 {
				border-color:  map.get($colors, 'black');
				color: map.get($colors, 'black') !important;
			}
		}

		.item-sub {
			margin-top: 5px;
		}

		.link-sub {
			color: map.get($colors, 'white');

			&:focus,
			&:hover {
				text-decoration: underline;
			}
	
	
		}
	}

	@include media-breakpoint-down(md) {
		display: none;
		background-color: map.get($colors, 'grey-light');
		padding-top: 15px;
		padding-bottom: 15px;
		.link {
			padding: 7.5px;
		}
	}
	
	
}

@include media-breakpoint-up(md) {
	#navigation-main {
		display: flex !important;
	}

	#button--mobile {
		display: none;
	}
}

@include media-breakpoint-up(md) {
	#navigation-main {
		display: flex !important;
	}
}

li {
	&[data-navsub] {
		&.focus {
			.sprite {
				transform: rotate(180deg);
			}
			> .link {
				color: map.get($colors, 'green-dark-alt')
			}
			> .navigation-sub {
				display: block;
				height: auto;
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.navigation-sub {
		color: map.get($colors, 'white');
		display: none;
		margin-left: 0;
		height: 0;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 178px;
		width: 100%;
		z-index: 99;

		@include transition(all, 0.1s, ease-in-out);

		@include media-breakpoint-down(xxl) {
			top: 180px
		}

		@include media-breakpoint-down(xl) {
			top: 168px
		}		

		@include media-breakpoint-down(lg) {
			top: 257px
		}		

		@include media-breakpoint-down(md) {
			height: auto;
			left: auto;
			margin-left: 0;
			opacity: 1;
			padding: 0 0 20px;
			margin-top: 0;
			position: relative;
			top: auto;
			width: 100%;
			
			.title-4 {
				@include font-size(15);
			}
		}
	}
}

#header-page {
	background-color: map.get($colors, 'green-dark-alt');
	background-image: url('assets/images/home-cover-2.jpg') !important;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	color: $white;
	padding: 100px 0;
	position: relative;
	text-align: center;

	.mask {
		background-color: rgba(0,0,0,.2);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
	}

	.container {
		position: relative;
		z-index: 2;
	}
	.title {
		@include font-size(50);
		&:after {
			content: "";
			display: block;
			margin: 5px auto 0;
			width: 60px;
			height: 4px;
			background-color: $white;
		}
	}

	.title-sub {
		display: block;
		margin-top: 10px;
		@include font-size(30);
	}

	@include media-breakpoint-down(md) {
		padding: 85px 0;
	}	
	
	@include media-breakpoint-down(md) {
		padding: 70px 0;
	}

	@include media-breakpoint-down(sm) {
		padding: 40px 0;
		.title {
			@include font-size(32);
		}
	}

	&.except-category {
		padding: 100px 0 60px;
		overflow: hidden;
		&.category-delanmen,
		&.category-impactjeunes {
			padding: 60px 0;
			@include media-breakpoint-down(md) {
				padding: 45px 0 !important;
			}		
			@include media-breakpoint-down(md) {
				padding: 30px 0 !important;
			}		
		}
		
		.mask {
			display: none;
		}

		@each $name, $sprite, $color in $categories {			
			&.#{$name} {
				background: $color !important;
				@if $name == category-impactjeunes {
					color: $black;
					.title{
						&:after {
							background-color: $black;
						}
					}
				}
				@if $name != category-delanmen and $name != category-impactjeunes {
					&:after {
						content: '';
						left: 50%;
						transform: scale(.8);
						margin: 0 0 0 -65px;
						position: absolute;
						top: 5px;
						@extend .sprite;
						@extend .#{$sprite};
					}
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding: 85px 0 45px !important;
		}	
		
		@include media-breakpoint-down(md) {
			padding: 70px  0 30px !important;
			&:after {
				top: -10px !important;
				transform: scale(.7) !important;
			}
		}
	
		@include media-breakpoint-down(sm) {
			padding: 30px 0 30px !important;
			&:after {
				display: none !important;
			}
			.title-sub {
				@include font-size(24);
			}
			&:after {
				top: -30px !important;
				transform: scale(.5) !important;
			}

		}
	
	}
}