$colors: (
    'black': #000000,
    'white': #FFFFFF,
    'green-light': #70C05F,
    'orange': #FFBE3C,
    'green-dark': #0B8264,
    'green-dark-alt': #319A8E,
    'red': #C2303A,
    'blue': #1779FE,
    'yellow': #FDD224,
    'grey': #D9D9D9,
    'grey-light': #f7f7f7,
    'grey-light-alt': #eeeeee,
    'error': #a94442,
);

@each $name,
$value in $colors {
  .color-#{$name} {
    color: $value;
  }

  .background-#{$name} {
    background-color: $value;
  }
}
