.product-item {
  height: 100%;
  
  &--link {
    background-color: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
    border-radius: 10px;
    border: 1px solid darken(map.get($colors, 'grey'), 10%);
    color: $black !important;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    justify-content: space-between;
    padding: 15px;
    @include transition(all, 0.2s, ease-in-out);
    
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      .button-regular {
        background-color: $black;
        color: $white;
      }
      img {
        transform: scale(1.1);
      }
    }
  }

  &--image {
    display: block;
    overflow: hidden;
    position: relative;

    img {
      @include transition(all, 0.05s, ease-in-out);
    }
  }

  &--col-bottom {
    padding-top: 20px;
    text-align: center;
  }
}

@include media-breakpoint-up(lg) {
  .filter-scroll {
    max-height: 130px;
    overflow-y: auto;
  }
}

.label-availability {
  border-radius: 5px;
  display: inline-block;
  font-family: $font-bold;
  line-height: 1em;
  text-transform: uppercase;
  padding: 5px 10px;
  @include font-size(11);

  &.available {
    background-color: map.get($colors, 'green-regular');
  }
  &.not-available {
    background-color: map.get($colors, 'orange');
  }
}
