.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: 15px;
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 14px;
		height: 14px;
		margin-right: 5px;
		background-color: color.scale(map.get($colors, 'black'), $lightness: 50%);
		border-radius: 100%;
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active {
		button {
			background-color: map.get($colors, 'black');
			&:focus,
			&:hover {
				background-color: map.get($colors, 'black');
			}
		}
	}
}

#slick-home-wrapper {
	background-color: map.get($colors, 'yellow');
	border-radius: 5px;
	padding: 50px 50px 50px 70px;

	.link {
		color: $black;
		display: block;

		.text-wrapper {
			transition: all 0.3s ease-in-out;
		}

		.img-wrapper {
			img {
				transition: all 0.1s ease-in-out;
			}
		}

		&:hover {
			.text-wrapper {
				transform: translateX(20px);
			}
			.img-wrapper {
				img {
					transform: scale(.95);
				}
			}
		}
	}
	

	#slick-home-arrows {

		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {
		bottom: -85px;
		left: 0;
		position: absolute;
		text-align: center;
		width: 100%;
		
		button {
			background-color: map.get($colors, 'green-dark-alt');
			height: 8px;
			width: 8px;
		}
		.slick-active {
			button {
				background-color: $black;
			}
		}
	}

	.slick-slide {
		.content {
			opacity: 0;
			transform: translateY(-30px) scale(1);
			@include transition(all, 0.2s, ease-in-out, 0.5s);
		}
		&.animation {
			.content {
				opacity: 1;
				transform: translateY(0) scale(1);
			}
		}
	}

	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {
		padding: 50px;
	}
	@include media-breakpoint-down(sm) {
		padding: 30px;
	}

	@include media-breakpoint-down(sm) {
		.slick-dots {
			bottom: -60px!important;
		}
	}
}

#slick-logos-wrapper {
	position: relative;
	&:after {
		content: '';
		background: linear-gradient(to right,  rgba(255,255,255,0) -1%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 100%);
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
	}
}