$sprites : (
  (scroll-top, 30px, 30px, 0 0),
  (category-icon-1, 128px, 128px, -41px 0),
  (category-icon-2, 128px, 128px, -171px 0),
  (category-icon-3, 128px, 128px, -301px 0),
  (category-icon-4, 128px, 128px, -431px 0),
  (social-facebook, 30px, 20px, -40px -160px),
  (social-instagram, 30px, 20px, -70px -160px),
  (social-youtube, 30px, 20px, -100px -160px),
  (social-twitter, 30px, 20px, -130px -160px),
  (social-linkedin, 30px, 20px, -160px -160px),
  (social-tiktok, 30px, 20px, -190px -160px),
  (menu-arrow, 10px, 10px, -220px -162px),
);

.sprite {
	background-image: url('assets/icons/sprite.png');
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: text-top;

  @each $label, $width, $height, $background-position in $sprites {
    &.#{$label} {
      width: $width;
      height: $height;
      background-position: $background-position;
    }
  }
}
