$categories : (
	(jaden-lesperances, map.get($colors, 'green-light')),
	(recyclbois, map.get($colors, 'orange')),
	(boost-insertion, map.get($colors, 'green-dark')),
	(propulse, map.get($colors, 'red')),
	(delanmen, map.get($colors, 'blue')),
	(impactjeunes, map.get($colors, 'yellow')),
);

.event {
  &--article {
    &.page-events {
      height: 100%;
      .link {
        position: relative;
        height: 100%;
        color: color.scale(map.get($colors, 'black'), $lightness: 30%);
        padding: 35px 0;
        &:after {
          content: "";
          background-color: map.get($colors, 'green-dark-alt');;
          bottom: 0;
          left: 0;
          position: absolute;
          width: 100%;
          height: 1px;
          transition: all .1s ease-in-out;
        }
        &:hover {
          &:after {
            bottom: 10px;
          }
        }
        @include media-breakpoint-down(sm) {
          padding: 30px 0;
        }
      }
    }
    .link {
      display: block;
      color: $white;

      .img-wrapper {
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        .category {
          bottom: 0;
          border-radius: 0 5px 0 0;
          color: $white;
          left: 0;
          padding: 3px 15px;
          position: absolute;
          z-index: 1;

          @each $name, $color in $categories {			
            &.#{$name} {
              @if $name == impactjeunes {
                color: $black;
              }
              background: $color;
            }
          }
        }
  

        img {
          transition: all .2s ease-in-out;
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}