#footer {
	background-color: $white;
	border-top: 10px solid map.get($colors, 'green-dark-alt');
	color: $black;

	.link-hover {
		&:hover {
			text-decoration: underline;
		}
	}

	a {
		&:hover {
			.sprite {
				transform: scale(.9);
			}
		}
	}

	.sprite {
		transition: all 0.2s ease-in-out;	
	}
	
	a {
		color: $black !important;
		@include transition(all, 0.2s, ease-in-out);	
		&.sprite {
			&:hover {
				transform: translateY(-5px);
			}
		}
	}

	.footer-copyright {
		border-top: 2px solid map.get($colors, 'green-dark-alt');
		padding: 10px 0;
		text-align: center;
	}

	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {}

	@include media-breakpoint-down(sm) {}
}